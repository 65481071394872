import { CardContent } from "@mui/material";
import { Button, Title } from "react-admin";
import { DateRangePicker, Stack } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import { useState } from "react";
import { DownloadButton } from "./analytics";
import { DateTime } from "luxon";

export const ArtistPayments = () => {
    const [range, setRange] = useState<DateRange | null>(null);
    let downloadUrl = undefined;
    let downloadFilename = `artist_payment.csv`;

    if (range !== null) {
        let startDate = DateTime.fromJSDate(range[0]).toFormat("yyyy-MM-dd");
        let endDate = DateTime.fromJSDate(range[1]).toFormat("yyyy-MM-dd");
        downloadUrl = import.meta.env.VITE_SERVER_URL + `/analytics/payment_csv?start_date=${startDate}&end_date=${endDate}`;
        downloadFilename = `artist_payment_${startDate}_${endDate}.csv`;
    }

    const handleDateRangeChange = (value: DateRange | null, event: any) => {
        setRange(value);
    };

    return (
        <div>
            <Title title="Artist payment" />
            <CardContent>
                <h1>Artist payment</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginBottom: "20px",
                        alignItems: "start",
                        marginTop: "20px",
                    }}
                >
                    <DateRangePicker onChange={handleDateRangeChange} format="yyyy-MM-dd" placeholder="Select Date Range" />

                    <DownloadButton url={downloadUrl} filename={downloadFilename} />
                </div>
            </CardContent>
        </div>
    );
};
