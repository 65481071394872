import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    ReferenceManyCount,
} from "react-admin";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { VisibilityBulkActionButtons } from "./common";
import { ColorField, ColorInput } from "./customFields";

const filters = [<TextInput source="q" label="Search" alwaysOn />];

export const GenreList = () => (
    <List filters={filters} perPage={50} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}>
        <Datagrid rowClick="show" bulkActionButtons={<VisibilityBulkActionButtons />}>
            <TextField source="name" />
            <ReferenceManyCount label="Nb tracks" reference="tracks" target="genre_ids" link />
            <BooleanField source="is_public" label="Public" />
            <EditButton />
        </Datagrid>
    </List>
);

export const GenreShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Genre Details</h2>

            <TextField source="name" />
            <TextField source="slug" />
            <TextField source="id" />
            <BooleanField source="is_public" />
            <ReferenceManyCount label="Nb tracks" reference="tracks" target="genre_ids" link />
            <ColorField source="color" />
        </SimpleShowLayout>
    </Show>
);

export const GenreEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Genre</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Genre Name"
            />
            <ColorInput source="color" />
            <BooleanInput source="is_public" />
        </SimpleForm>
    </Edit>
);

export const GenreCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Genre</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Genre Name"
            />
            <ColorInput source="color" />
            <BooleanInput source="is_public" defaultValue={false} />
        </SimpleForm>
    </Create>
);
