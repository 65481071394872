import { Layout, Menu } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";

export const AppMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.ResourceItems />
        <Menu.Item to="/featured_tracks" primaryText="Featured Tracks" leftIcon={<SettingsIcon />} />
    </Menu>
);
