const calloutBoxStyle = {
    border: "1px solid #ccc",
    borderRadius: "16px",
    padding: "15px",
    margin: "20px 0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
};

export const NamingCallout = () => {
    return (
        <div style={calloutBoxStyle}>
            <strong>⚠️ Naming conventions</strong>
            <p>
                Except in some cases where the name follows a specific rule by design, the name should follow the standard music
                naming conventions:
            </p>
            <ul>
                <li>If the name is 1 or 2 words, capitalize all words.</li>
                <li>
                    If the name is 3 words or more:
                    <ul>
                        <li>The first and last word are always capitalized.</li>
                        <li>
                            Capitalize other words <strong>except:</strong>
                            <ul>
                                <li>
                                    <em>and, but, or, nor, a, an, the</em>
                                </li>
                                <li>
                                    <em>for, yet, so</em> when used as conjunctions
                                </li>
                                <li>
                                    <em>to,</em> when used as an infinitive
                                </li>
                                <li>
                                    prepositions that are strictly shorter than 5 letters (<em>as, in, to, like, with,</em> etc.)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Rules regarding featurings, radio edits, remix etc.
                    <ul>
                        <li>Track Name (feat. XXX)</li>
                        <li>Track Name (Radio Edit)</li>
                        <li>Track Name (Instrumental)</li>
                        <li>Track Name (XXX Remix)</li>
                    </ul>
                </li>
            </ul>
            <strong>
                Examples: Me and You (Instrumental), Like Myself (Victor Remix), I Used to Live (feat. Victor), I Used to Like
                You, I Did It like You, Why Don’t You Say So
            </strong>
        </div>
    );
};

export const MandatoryFieldCallout = () => {
    return (
        <div style={calloutBoxStyle}>
            <strong>⚠️ This field is mandatory</strong>
        </div>
    );
};

export const AudioFormatCallout = () => {
    return (
        <div style={calloutBoxStyle}>
            <strong>⚠️ Audio format needs to be WAV 44,100 kHz</strong>
        </div>
    );
};

export const ArtworkFormatCallout = () => {
    return (
        <div style={calloutBoxStyle}>
            <strong>⚠️ You need to ensure that artwork is a square image</strong>
        </div>
    );
};
