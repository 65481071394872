import { Button, Dialog, DialogActions, DialogContent, TextField as MuiTextField } from "@mui/material";
import { useState } from "react";
import {
    Create,
    Datagrid,
    EditButton,
    List,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    useCreateSuggestionContext,
    useCreate,
    LinearProgress,
} from "react-admin";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { BulkActionButtons } from "./common";

const filters = [<TextInput source="q" label="Search" alwaysOn />];

export const TagList = () => (
    <List filters={filters} perPage={50} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}>
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="name" />
            <EditButton />
        </Datagrid>
    </List>
);

export const TagShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Tag Details</h2>

            <TextField source="id" />
            <TextField source="name" />
        </SimpleShowLayout>
    </Show>
);

export const TagEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Tag</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Tag Name" />
        </SimpleForm>
    </Edit>
);

export const TagCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Tag</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Tag Name" />
        </SimpleForm>
    </Create>
);

export const TagCreatePopup = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || "");
    const [create, { isLoading, error }] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const newTag = { name: value };

        create("tags", { data: newTag }, { returnPromise: true }).then(() => {
            setValue("");
            onCreate(newTag);
        });
    };

    return (
        <Dialog open onClose={onCancel}>
            {isLoading ? <LinearProgress></LinearProgress> : null}
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField label="Tag name" value={value} onChange={(event) => setValue(event.target.value)} autoFocus />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
