import React, { Fragment, ReactElement, useState } from "react";
import {
    BulkActionProps,
    Button,
    ButtonProps,
    Confirm,
    Form,
    MutationMode,
    RaRecord,
    RecordContextProvider,
    SaveContextProvider,
    SimpleForm,
    TextInput,
    useListContext,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useUnselectAll,
    useUpdateMany,
} from "react-admin";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material";
import ActionUpdate from "@mui/icons-material/Update";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { errorMessage } from "./utils";

export const BulkUpdateForm = (props: BulkUpdateFormProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const resource = useResourceContext(props);
    const unselectAll = useUnselectAll(resource);
    const [isOpen, setOpen] = useState(false);
    const { selectedIds } = useListContext(props);

    const {
        confirmTitle = "ra.message.bulk_update_title",
        icon = defaultIcon,
        label = "ra.action.update",
        onClick,
        onSuccess = () => {
            refresh();
            notify("ra.notification.updated", {
                type: "info",
                messageArgs: { smart_count: selectedIds.length },
            });
            unselectAll();
            setOpen(false);
        },
        onError = (error) => {
            notify(errorMessage(error, "Unable to update"), { type: "error" });
            setOpen(false);
        },
        ...rest
    } = props;

    const [updateMany] = useUpdateMany();

    const handleClick = (e) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const postSave = (data: any) => {
        updateMany(
            resource,
            { ids: selectedIds, data },
            {
                onSuccess,
                onError,
            }
        );
    };

    return (
        <Fragment>
            <StyledButton onClick={handleClick} label={label} {...sanitizeRestProps(rest)}>
                {icon}
            </StyledButton>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Edit {selectedIds.length} {resource}
                </DialogTitle>
                <DialogContent>
                    <SimpleForm onSubmit={postSave}>{props.children}</SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

const sanitizeRestProps = ({
    filterValues,
    label,
    onSuccess,
    onError,
    ...rest
}: Omit<BulkUpdateFormProps, "resource" | "selectedIds" | "icon" | "data">) => rest;

export interface BulkUpdateFormProps<RecordType extends RaRecord = any, MutationOptionsError = unknown>
    extends BulkActionProps,
        ButtonProps {
    confirmContent?: React.ReactNode;
    confirmTitle?: React.ReactNode;
    icon?: ReactElement;
    onSuccess?: () => void;
    onError?: (error: any) => void;
}

BulkUpdateForm.propTypes = {
    confirmTitle: PropTypes.node,
    label: PropTypes.string,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    icon: PropTypes.element,
};

const PREFIX = "BulkUpdateForm";

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    color: theme.palette.primary.main,
    "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
            backgroundColor: "transparent",
        },
    },
}));

const defaultIcon = <ActionUpdate />;
