import { Create, Datagrid, List, Show, SimpleShowLayout, TextField, Pagination, SelectField, ReferenceField } from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField } from "./customFields";
import { BulkActionButtons } from "./common";

const ddexDryRunFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const DDEXDryRunStatuses = [
    { id: "processing", name: "⏳ Processing" },
    { id: "completed", name: "✅ Completed" },
    { id: "error", name: "❌ Error" },
];

export const DDEXDryRunList = () => (
    <List
        filters={ddexDryRunFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="id" />
            <ReferenceField reference="ddex/messages" source="message_id" link="show" sortable={false} />
            <SelectField source="status" label="Status" choices={DDEXDryRunStatuses} sortable={false} />
            <LongDateField source="created_at" />
        </Datagrid>
    </List>
);

export const DDEXDryRunShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>DDEX Dry Run</h2>

            <TextField source="id" />
            <ReferenceField reference="ddex/messages" source="message_id" link="show" />
            <SelectField source="status" label="Status" choices={DDEXDryRunStatuses} />
            <TextField source="logs" component="pre" fontFamily={"monospace"} />
            <LongDateField source="created_at" label="Created at" />
        </SimpleShowLayout>
    </Show>
);
