import { CardContent } from "@mui/material";
import { RecordContextProvider, SaveContextProvider, SimpleForm, Title, useAuthenticated, useNotify } from "react-admin";
import "rsuite/DateRangePicker/styles/index.css";
import { useEffect, useState } from "react";
import { httpClient } from "./dataProvider";

export const SettingsUpdate = (props: { url: string; children: React.ReactNode }) => {
    useAuthenticated();
    const [record, setRecord] = useState();
    const [saving, setSaving] = useState(false);
    const notify = useNotify();
    const mutationMode = "pessimistic";

    useEffect(() => {
        httpClient(import.meta.env.VITE_SERVER_URL + props.url, { method: "GET" })
            .then(({ json }) => {
                setRecord(json);
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
            });
    }, []);

    const save = async (data: any) => {
        var body = JSON.stringify(data);

        setSaving(true);
        try {
            await httpClient(import.meta.env.VITE_SERVER_URL + props.url, { method: "POST", body: body });
            setSaving(false);
            setRecord(data);
            notify("Updated");
        } catch (error: any) {
            setSaving(false);
            notify(error.toString(), { type: "error" });
        }
    };

    return (
        <div>
            <Title title="Featured tracks" />
            <CardContent>
                <h1>Featured tracks</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginBottom: "20px",
                        alignItems: "start",
                        marginTop: "20px",
                    }}
                >
                    {!!record && (
                        <SaveContextProvider value={{ save, saving, mutationMode }}>
                            <RecordContextProvider value={record}>
                                <SimpleForm onSubmit={save}>{props.children}</SimpleForm>
                            </RecordContextProvider>
                        </SaveContextProvider>
                    )}
                </div>
            </CardContent>
        </div>
    );
};
