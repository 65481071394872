import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    DateField,
    SelectField,
    SelectInput,
    useEditController,
    DateTimeInput,
    DateFieldProps,
    fetchRelatedRecords,
    useRecordContext,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { DurationField, MediaUploadField, SaveAndAddAnotherToolbar, LongDateField, ShortDateField } from "./customFields";

const artistAccessFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const ArtistAccessList = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        filters={artistAccessFilters}
    >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="email" />
            <BooleanField source="can_upload_demo" />
            <EditButton />
        </Datagrid>
    </List>
);

export const ArtistAccessShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Artist CMS access</h2>
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="user_id" />
            <BooleanField source="can_upload_demo" />
            <EditButton />
        </SimpleShowLayout>
    </Show>
);

export const ArtistAccessCreate = () => (
    <Create>
        <SimpleForm toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add artist CMS access</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Name" />
            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="email" label="Email" />
            <BooleanInput source="can_upload_demo" defaultValue={true} />
        </SimpleForm>
    </Create>
);

export const ArtistAccessEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit artist CMS access</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Name" />
            <BooleanInput source="can_upload_demo" defaultValue={true} />
        </SimpleForm>
    </Edit>
);
