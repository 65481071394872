import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    SelectField,
    ReferenceField,
    ReferenceArrayField,
    ReferenceArrayInput,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";

export const DDEXGenreMappingList = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="ddex_name" />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <ShortDateField source="created_at" label="Added on" />
        </Datagrid>
    </List>
);

export const DDEXGenreMappingShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>DDEXGenreMapping</h2>

            <TextField source="id" />
            <TextField source="ddex_name" />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <ShortDateField source="created_at" label="Added on" />
        </SimpleShowLayout>
    </Show>
);

export const DDEXGenreMappingCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add DDEXGenreMapping</h2>

            <TextInput source="ddex_name" fullWidth />
            <ReferenceArrayInput label="Genres" reference="genres" source="genre_ids" />
        </SimpleForm>
    </Create>
);

export const DDEXGenreMappingEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit DDEXGenreMapping</h2>

            <TextInput source="ddex_name" fullWidth />
            <ReferenceArrayInput label="Genres" reference="genres" source="genre_ids" />
        </SimpleForm>
    </Edit>
);
