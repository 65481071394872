import { Link, usePermissions } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { WavePermissions } from "./auth";

const PaymentButton = () => (
    <Link component={RouterLink} to="/payments">
        Go to artist payment
    </Link>
);

const Dashboard = () => {
    const { isLoading, permissions } = usePermissions<WavePermissions>();

    if (isLoading) {
        return <p>Loading..</p>;
    }

    switch (permissions.mode) {
        case "admin": {
            return (
                <div>
                    <h1>Welcome to the Carbon music catalog.</h1>
                    <p>Use the categories on the left panel to access, edit and add new content.</p>
                    <p>
                        Need help? Ask us directly on Slack{" "}
                        <a href="https://voodoo-team.slack.com/channels/spot-voodoo-music">#spot-voodoo-music</a>
                    </p>
                    <PaymentButton />
                </div>
            );
        }
        case "artist": {
            return (
                <div>
                    <h1>Welcome to the Carbon music catalog.</h1>
                    <p>Use the categories on the left panel to register your demos</p>
                </div>
            );
        }
    }
};

export default Dashboard;
