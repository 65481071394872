import { Create, Datagrid, List, Show, SimpleShowLayout, TextField, Pagination, SelectField, ReferenceField } from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField } from "./customFields";
import { BulkActionButtons } from "./common";

const userFeedbackFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const UserFeedbackList = () => (
    <List
        filters={userFeedbackFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="email" />
            <TextField source="text" sortable={false} />
            <LongDateField source="created_at" />
        </Datagrid>
    </List>
);

export const UserFeedbackShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>User Feedback</h2>

            <TextField source="id" />
            <TextField source="user_id" />
            <LongDateField source="created_at" />
            <TextField source="email" />
            <TextField source="text" />
        </SimpleShowLayout>
    </Show>
);
