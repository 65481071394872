import { ArrayInput, SimpleFormIterator } from "react-admin";
import "rsuite/DateRangePicker/styles/index.css";
import { TrackReferenceInput } from "./tracks";
import { SettingsUpdate } from "./settingUpdate";

export const FeaturedTracks = () => {
    return (
        <SettingsUpdate url="/cms/featured_tracks">
            <ArrayInput source="track_ids" fullWidth label="Tracks">
                <SimpleFormIterator inline>
                    <TrackReferenceInput />
                </SimpleFormIterator>
            </ArrayInput>
        </SettingsUpdate>
    );
};
