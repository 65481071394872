import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    DateField,
    SelectField,
    SelectInput,
    useEditController,
    DateTimeInput,
    DateFieldProps,
    fetchRelatedRecords,
    useRecordContext,
    useRedirect,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { DurationField, MediaUploadField, SaveAndAddAnotherToolbar, LongDateField, ShortDateField } from "./customFields";
import { Button } from "@mui/material";

const DemoTrackStatuses = [
    { id: "waiting_for_review", name: "⏳ Waiting for review" },
    { id: "approved", name: "✅ Approved" },
    { id: "rejected", name: "❌ Rejected" },
];

const AudioPlayerField = () => {
    const record = useRecordContext();

    if (!record.is_audio_ready) {
        return <strong>Processing audio...</strong>;
    } else {
        return (
            <audio controls src={record.audio_url}>
                Your browser does not support the audio element.
            </audio>
        );
    }
};

const demoTracksFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const DemoTrackList = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        filters={demoTracksFilters}
        exporter={false}
    >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <SelectField source="status" label="Status" choices={DemoTrackStatuses} />
            <DateField source="updated_at" label="Updated on" showTime />
        </Datagrid>
    </List>
);

export const DemoTrackShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Demo</h2>
            <TextField source="name" />
            <SelectField source="status" label="Status" choices={DemoTrackStatuses} />
            <TextField source="comment" />
            <AudioPlayerField />
        </SimpleShowLayout>
    </Show>
);

export const DemoTrackCreate = () => (
    <Create>
        <SimpleForm toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add Demo</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Demo Name" />
            <MediaUploadField mediaType="audio" requiresValidation={true} />
        </SimpleForm>
    </Create>
);

// Admin

const demoTracksFiltersAdmin = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput source="artist_access_id" label="Artist" reference="artist_access" />,
    <SelectInput source="status" choices={DemoTrackStatuses} />,
];

export const DemoTrackListAdmin = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        filters={demoTracksFiltersAdmin}
    >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <SelectField source="status" label="Status" choices={DemoTrackStatuses} />
            <DateField source="updated_at" label="Updated on" showTime />
            <ReferenceField source="artist_access_id" reference="artist_access" label="Artist" link="show" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);

const CreateTrackButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();

    const onClick = () => {
        const data = {
            name: record.name,
            audio_upload_id: record.audio_upload_id,
            audio_url: record.audio_url,
        };

        redirect(`/tracks/create?source=${JSON.stringify(data)}`);
    };

    if (!record) return null;
    return (
        <Button onClick={onClick} variant="contained" color="primary">
            Create Track from demo
        </Button>
    );
};

export const DemoTrackShowAdmin = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Demo</h2>
            <TextField source="name" />
            <ReferenceField source="artist_access_id" reference="artist_access" label="Artist" link="show" sortable={false} />
            <SelectField source="status" label="Status" choices={DemoTrackStatuses} />
            <TextField source="comment" />
            <AudioPlayerField />
            <TextField source="created_at" />
            <TextField source="updated_at" />
            <EditButton />
            <CreateTrackButton />
        </SimpleShowLayout>
    </Show>
);

export const DemoTrackEditAdmin = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Demo</h2>

            <TextInput sx={{ width: 500 }} autoComplete="off" validate={[required()]} fullWidth source="name" label="Demo Name" />
            <SelectInput source="status" choices={DemoTrackStatuses} validate={[required()]} />
            <TextInput sx={{ width: 500 }} autoComplete="off" multiline={true} fullWidth source="comment" label="Comment" />
        </SimpleForm>
    </Edit>
);
