import {
    useRecordContext,
    required,
    FileField,
    FileInput,
    FormDataConsumer,
    useNotify,
    Toolbar,
    SaveButton,
    DateField,
    DateFieldProps,
    ReferenceInput,
    AutocompleteInput,
    ReferenceInputProps,
    TextField,
    TextInput,
} from "react-admin";
import { ArtworkFormatCallout, AudioFormatCallout } from "./callouts";
import { useFormContext } from "react-hook-form";
import XMLViewer from "react-xml-viewer";

export const DurationField = ({ source, ...props }) => {
    const record = useRecordContext();

    var timeString;
    if (record && record[source]) {
        var date = new Date(0);
        date.setSeconds(record[source] / 1000);
        timeString = date.toISOString().substring(11, 19);
    } else {
        timeString = "";
    }
    return <span>{timeString}</span>;
};

export const AudioPlayerField = ({ ...props }) => {
    const record = useRecordContext();

    if (record.audio_url == null) {
        return <></>;
    } else {
        return (
            <audio controls src={record.audio_url}>
                Your browser does not support the audio element.
            </audio>
        );
    }
};

export const XMLField = (props: { source: string }) => {
    const record = useRecordContext();

    return (
        <div style={{ fontSize: "13px" }}>
            <XMLViewer xml={record[props.source]} />
        </div>
    );
};

export const MediaUploadField = ({ mediaType, requiresValidation }) => {
    const validationProps = requiresValidation ? { validate: [required()] } : {};

    const PreviewComponent = mediaType === "audio" ? AudioPreview : ArtworkPreview;
    const label = mediaType === "audio" ? "Audio File" : "Artwork";

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 2, maxWidth: "500px" }}>
                <FileInput {...validationProps} fullWidth source={mediaType} label={label}>
                    <FileField source="src" title="title" />
                </FileInput>

                <PreviewComponent />
            </div>
        </div>
    );
};

export const ArtworkPreview = () => (
    <FormDataConsumer>
        {({ formData, ...rest }) => {
            if (formData.image && formData.image.rawFile) {
                const objectURL = URL.createObjectURL(formData.image.rawFile);
                return <img src={objectURL} alt="Artwork Preview" style={{ maxWidth: "100%", maxHeight: "300px" }} />;
            } else if (formData.image_url) {
                return <img src={formData.image_url} alt="Artwork Preview" style={{ maxWidth: "100%", maxHeight: "300px" }} />;
            } else {
                return (
                    <div style={{ padding: "1rem", border: "1px dashed #ccc" }}>
                        Artwork preview will appear here after upload
                    </div>
                );
            }
        }}
    </FormDataConsumer>
);

export const AudioPreview = () => (
    <FormDataConsumer>
        {({ formData, ...rest }) => {
            if (formData.audio && formData.audio.src) {
                return (
                    <audio controls src={formData.audio.src}>
                        Your browser does not support the audio element.
                    </audio>
                );
            } else if (formData.audio_url && formData.audio_url) {
                return (
                    <audio controls src={formData.audio_url}>
                        Your browser does not support the audio element.
                    </audio>
                );
            } else {
                return (
                    <div style={{ padding: "1rem", border: "1px dashed #ccc" }}>Audio preview will appear here after upload</div>
                );
            }
        }}
    </FormDataConsumer>
);

export const SaveAndAddAnotherToolbar = () => {
    const notify = useNotify();
    const { reset } = useFormContext();

    return (
        <Toolbar style={{ gap: "10px" }}>
            <SaveButton />
            <SaveButton
                type="button"
                label="Save and add another"
                mutationOptions={{
                    onSuccess: () => {
                        reset();
                        window.scrollTo(0, 0);
                        notify("ra.notification.created", {
                            type: "info",
                            messageArgs: { smart_count: 1 },
                        });
                    },
                }}
            />
        </Toolbar>
    );
};

export const LongDateField = (props: DateFieldProps) => (
    <DateField
        {...props}
        options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
        }}
    />
);

export const ShortDateField = ({ source, ...props }) => {
    const record = useRecordContext();

    var timeString;
    if (record && record[source]) {
        var date = new Date(record[source]);
        timeString = date.toISOString().slice(0, 10);
    } else {
        timeString = "";
    }
    return <span>{timeString}</span>;
};

export const NullableReferenceInput = (props: ReferenceInputProps) => {
    const record = useRecordContext();
    const recordExists = !!record;

    return (
        <ReferenceInput {...props}>
            <AutocompleteInput defaultValue={recordExists ? undefined : null} fullWidth />
        </ReferenceInput>
    );
};

export const ColorField = (props: { source: string }) => {
    const record = useRecordContext();

    return record[props.source] ? <input type="color" value={record[props.source]} disabled /> : <></>;
};

export const ColorInput = (props: { source: string }) => {
    const record = useRecordContext();

    return (
        <>
            <FormDataConsumer>
                {({ formData, ...rest }) => <input type="color" value={formData[props.source]} disabled />}
            </FormDataConsumer>

            <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <TextInput fullWidth source={props.source} label="Color" />
            </div>
        </>
    );
};
