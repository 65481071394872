import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceField,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    DateInput,
    DateField,
    SavedQueriesList,
    FilterLiveSearch,
    FilterList,
    FilterListItem,
    ReferenceArrayInput,
    ReferenceArrayField,
    ArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    fetchRelatedRecords,
    downloadCSV,
    DatagridConfigurable,
    WrapperField,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    AutocompleteArrayInput,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { DurationField, LongDateField, MediaUploadField, SaveAndAddAnotherToolbar, ShortDateField } from "./customFields";
import { NamingCallout } from "./callouts";
import { Card, CardContent } from "@mui/material";
import CategoryIcon from "@mui/icons-material/LocalOffer";
import { captureError } from "./sentry";
import { VisibilityBulkActionButtons } from "./common";
import { useCallback } from "react";
import { ArtistReferenceInput, ManyArtistReferenceInput } from "./artists";
import { LabelReferenceInput } from "./labels";
import Tooltip from "@mui/material/Tooltip";
import { TrackReferenceInput } from "./tracks";
import { DEFAULT_IS_PUBLIC } from "./config";
import { dataProvider } from "./dataProvider";
import jsonExport from "jsonexport/dist";
import { TagCreatePopup } from "./tags";

const albumFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="is_public" label="Public" defaultValue={true} />,
    <ArtistReferenceInput source="artist_id" label="Artist" />,
    <LabelReferenceInput source="label_id" label="Label" />,
    <ReferenceArrayInput source="genre_ids" label="Genre" reference="genres" />,
    <ReferenceArrayInput source="tag_ids" label="Tag" reference="tags" />,
    <BooleanInput source="is_ddex_available" label="DDEX available" defaultValue={false} />,
    <BooleanInput source="is_ddex_upload" label="DDEX upload" defaultValue={true} />,
];

const exporter = async (albums: any) => {
    let genres = await fetchRelatedRecords(dataProvider)(albums, "genre_ids", "genres");
    let artists = await fetchRelatedRecords(dataProvider)(albums, "artist_id", "artists");

    const data = albums.map((album: any) => ({
        id: album.id,
        name: album.name,
        artist_name: artists[album.artist_id].name,
        genres: album.genre_ids.map((genre_id: number) => genres[genre_id].name),
        is_public: album.is_public,
        image_url: album.image_url,
    }));

    jsonExport(
        data,
        {
            headers: ["id", "name", "artist_name", "genres", "is_public", "image_url"],
        },
        (err: any, csv: any) => {
            downloadCSV(csv, "albums");
        }
    );
};

const AlbumActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const AlbumList = () => (
    <List
        filters={albumFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={exporter}
        actions={<AlbumActions />}
    >
        <DatagridConfigurable rowClick="show" bulkActionButtons={<VisibilityBulkActionButtons />} omit={["is_ddex_available"]}>
            <TextField source="name" />
            <ImageField source="thumbnail_url" title="Album cover" label="Image" sortable={false} />
            <ReferenceField source="artist_id" reference="artists" label="Artist" link="show" sortable={false} />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" sortable={false} />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" sortable={false} />
            <BooleanField source="is_public" label="Public" />
            <BooleanField source="is_ddex_available" label="DDEX Available" />
            <ShortDateField source="created_at" label="Added on" />
            <WrapperField label="Edit">
                <EditButton />
            </WrapperField>
        </DatagridConfigurable>
    </List>
);

export const AlbumShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Album Details</h2>

            <TextField source="name" />
            <TextField source="description" />
            <ImageField source="image_url" title="Album cover" label="Image" />
            <ArrayField source="status" label="Processing Status">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                </SingleFieldList>
            </ArrayField>
            <TextField source="id" />
            <ReferenceField source="artist_id" reference="artists" label="Artist" link="show" />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <BooleanField source="is_public" />
            <BooleanField source="is_ddex_available" />
            <ReferenceField source="label_id" reference="labels" label="Label" link="show" />
            <ReferenceArrayField label="Featured artists" reference="artists" source="featured_artist_ids" />
            <LongDateField source="created_at" label="Added on" />
            <DateField source="release_date" />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" />
            <ReferenceManyField label="Tracks" reference="tracks" target="album_id">
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Album cover" label="Image" />
                    <DurationField source="duration_ms" label="Duration" />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

const validateAlbum = (values: any) => {
    try {
        const errors: any = {};
        console.log(values);
        if (values.genre_ids && values.genre_ids.length === 0) {
            errors.genre_ids = "Missing genres";
        }
        if (values.description && values.description.length > 1000) {
            errors.description = `Description too big (${values.description.length}): 1000 chars maximum`;
        }
        return errors;
    } catch (error) {
        captureError(error);
        throw error;
    }
};

export const AlbumEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm validate={validateAlbum}>
            <h2>Edit Album</h2>

            <AlbumDetailsEditor />

            <h2>Media Upload</h2>

            <MediaUploadField mediaType="image" requiresValidation={false} />
            <TextInput sx={{ width: 500 }} source="id" InputProps={{ disabled: true }} />
        </SimpleForm>
    </Edit>
);

export const AlbumCreate = () => (
    <Create>
        <SimpleForm validate={validateAlbum} toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add Album</h2>

            <AlbumDetailsEditor />

            <h2>Media Upload</h2>

            <MediaUploadField mediaType="image" requiresValidation={false} />
        </SimpleForm>
    </Create>
);

export const AlbumDetailsEditor = () => (
    <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 2, maxWidth: "500px" }}>
                <TextInput autoComplete="off" validate={[required()]} fullWidth source="name" label="Album Name" />
                <TextInput
                    sx={{ width: 500 }}
                    autoComplete="off"
                    multiline={true}
                    fullWidth
                    source="description"
                    label="Album Description"
                />
                <ArtistReferenceInput />
                <LabelReferenceInput />
                <ReferenceArrayInput label="Genres" source="genre_ids" reference="genres" />
                <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
                <ManyArtistReferenceInput label="Featured artists" source="featured_artist_ids" />
                <Tooltip title="Release date shown in the app, only informational">
                    <DateInput source="release_date" />
                </Tooltip>
            </div>

            <div style={{ flex: 1, marginLeft: "20px" }}>
                <NamingCallout />
            </div>
        </div>
        <ArrayInput source="track_ids" fullWidth label="Tracks">
            <SimpleFormIterator inline>
                <TrackReferenceInput />
            </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput source="tag_ids" reference="tags">
            <AutocompleteArrayInput label="Tags" create={<TagCreatePopup />} />
        </ReferenceArrayInput>
    </div>
);

const AlbumOptionRenderer = () => {
    const record = useRecordContext();
    return (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src={record.thumbnail_url} style={{ width: "40px" }} />
            {record.name}
        </div>
    );
};
const albumOptionText = <AlbumOptionRenderer />;
const albumInputText = (choice: any) => `${choice.name}`;

export const AlbumReferenceInput = (props: { label?: string; source?: string }) => {
    const record = useRecordContext();
    const recordExists = !!record;
    const { label = "Album", source = "album_id" } = props;

    return (
        <ReferenceInput fullWidth source={source} reference="albums" label={label}>
            <AutocompleteInput
                inputText={albumInputText}
                optionText={albumOptionText}
                defaultValue={recordExists ? undefined : null}
                fullWidth
            />
        </ReferenceInput>
    );
};
