import { withLifecycleCallbacks } from "react-admin";
import { stringify } from "query-string";
import { fetchUtils, DataProvider, CreateParams, UpdateParams } from "ra-core";
import { HttpError } from "react-admin";
import { getFirebaseAuth } from "./auth";

const dataHttpProvider = (apiUrl, httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                );
            }
            return {
                data: json,
                total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
            };
        });
    },

    getOne: (resource, params) => {
        const query = {
            id: params.id,
            details: 1,
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(({ json }) => ({
            data: json[0],
        }));
    },

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
            start: 0,
            end: 1000,
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(({ json }) => ({
            data: json,
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                );
            }
            return {
                data: json,
                total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
            };
        });
    },

    update: (resource, params) => {
        var body = JSON.stringify(params.data);

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: body,
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        var body = JSON.stringify(params.data);
        const query = {
            id: params.ids,
        };

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "PATCH",
            body: body,
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        var body = JSON.stringify(params.data);
        return httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: body,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id } as any,
        }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: "DELETE",
                })
            )
        ).then((responses) => ({ data: responses.map(({ json }) => json) })),
});

export const httpClient = async (url: string, options: any = {}) => {
    if (!url.startsWith(import.meta.env.VITE_SERVER_URL)) {
        // Avoid sending auth elsewhere
        throw new Error("Invalid url: this fonction should only be used for wave api");
    }

    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const auth = await getFirebaseAuth();
    const user = auth.currentUser;

    if (user) {
        let token = await user.getIdToken();
        options.headers.set("Authorization", `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

export const plainHttpClient = async (url: string, init: RequestInit) => {
    if (!url.startsWith(import.meta.env.VITE_SERVER_URL)) {
        // Avoid sending auth elsewhere
        throw new Error("Invalid url: this fonction should only be used for wave api");
    }

    const auth = await getFirebaseAuth();
    const user = auth.currentUser;

    if (user) {
        let token = await user.getIdToken();
        init.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    let response = await fetch(url, init);
    if (response.status < 200 || response.status >= 300) {
        throw new HttpError(await response.text(), response.status);
    }
    return response;
};

const baseDataProvider = dataHttpProvider(import.meta.env.VITE_SERVER_URL + "/cms", httpClient);

const isFileValid = (file: File, type_prefix: string): boolean => {
    if (file.type.startsWith(type_prefix)) {
        return true;
    }
    if (type_prefix === "audio" && (file.name.endsWith(".aiff") || file.name.endsWith(".aif"))) {
        return true;
    }
    return false;
};

const uploadFile = async (file: File, type_prefix: string): Promise<string> => {
    if (!isFileValid(file, type_prefix)) {
        throw Error(`Invalid file type: ${file.type}, expected ${type_prefix}`);
    }

    const hash = await crypto.subtle.digest("SHA-256", await file.arrayBuffer());
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");

    let r = await httpClient(import.meta.env.VITE_SERVER_URL + "/uploads", {
        method: "POST",
        body: JSON.stringify({ hash_sha256: hashHex }),
    });

    let fileUpload: FileUpload = r.json;

    if (fileUpload.completed) {
        return fileUpload.id;
    }

    let upload = fileUpload.upload;

    const formData = new FormData();

    for (const [key, value] of Object.entries(upload.fields)) {
        formData.append(key, value);
    }
    formData.append("file", file);

    let uploadResponse = await fetch(upload.url, {
        method: "POST",
        body: formData,
    });
    if (uploadResponse.status !== 200 && uploadResponse.status !== 204) {
        console.log(await uploadResponse.text());
        throw Error("Unable to upload");
    }

    while (true) {
        let r = await httpClient(import.meta.env.VITE_SERVER_URL + `/uploads/${fileUpload.id}`);
        if (!!r.json.completed) {
            break;
        }

        await new Promise((r) => setTimeout(r, 1000));
    }

    return fileUpload.id;
};

type FileUpload = {
    id: string;
    completed: boolean;
    upload: {
        url: string;
        fields: Record<string, string>;
    };
};

export const dataProvider = withLifecycleCallbacks(baseDataProvider, [
    {
        resource: "demo_tracks",
        beforeSave: async (params: any, dataProvider: DataProvider) => {
            if (!params.audio) {
                return params;
            }

            const uploadId = await uploadFile(params.audio.rawFile, "audio");

            return {
                ...params,
                audio_upload_id: uploadId,
            };
        },
    },
    {
        resource: "artists",
        beforeSave: async (params: any, dataProvider: DataProvider) => {
            if (!params.image) {
                return params;
            }

            const uploadId = await uploadFile(params.image.rawFile, "image");

            return {
                ...params,
                image_upload_id: uploadId,
            };
        },
    },
    {
        resource: "albums",
        beforeSave: async (params: any, dataProvider: DataProvider) => {
            if (!params.image) {
                return params;
            }

            const uploadId = await uploadFile(params.image.rawFile, "image");

            return {
                ...params,
                image_upload_id: uploadId,
            };
        },
    },
    {
        resource: "tracks",
        beforeSave: async (params: any, dataProvider: DataProvider) => {
            console.log(params);
            if (params.image) {
                const uploadId = await uploadFile(params.image.rawFile, "image");

                params = {
                    ...params,
                    image_upload_id: uploadId,
                };
            }

            if (params.audio) {
                console.log(params.audio);
                const uploadId = await uploadFile(params.audio.rawFile, "audio");

                params = {
                    ...params,
                    audio_upload_id: uploadId,
                };
            }

            return params;
        },
    },
    {
        resource: "labels",
        beforeSave: async (params: any, dataProvider: DataProvider) => {
            if (!params.image) {
                return params;
            }

            const uploadId = await uploadFile(params.image.rawFile, "image");

            return {
                ...params,
                image_upload_id: uploadId,
            };
        },
    },
]);
