import { useBasename, useNotify } from "ra-core";
import { Login, LoginForm } from "ra-ui-materialui";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router";
import { authProvider } from "./auth";
import { errorMessage } from "./utils";
import { useCheckAuth } from "ra-core";
import appleLoginImage from "./assets/apple-id-sign-in.png";
import appIcon from "./assets/AppIcon.png";
import { useEffect } from "react";
import { Card, styled } from "@mui/material";

export const CustomLogin = () => {
    const checkAuth = useCheckAuth();
    const navigate = useNavigate();
    const basename = useBasename();
    const notify = useNotify();

    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                navigate("/");
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, navigate]);

    return (
        <Root>
            <Card className={LoginClasses.card}>
                <div className={LoginClasses.icon}>
                    <img src={appIcon} style={{ width: "64px", height: "auto", borderRadius: "10%" }} />
                </div>
                <div className={LoginClasses.methods}>
                    <h3 style={{ marginTop: "0" }}>Voodoo Music</h3>
                    <GoogleButton
                        onClick={() => {
                            authProvider
                                .loginWithGoogle()
                                .then(() => {
                                    navigate(`${basename}/`);
                                })
                                .catch((error: any) => {
                                    notify(errorMessage(error, "ra.auth.sign_in_error"), { type: "error" });
                                });
                        }}
                    />
                    <div
                        onClick={() => {
                            authProvider
                                .loginWithApple()
                                .then(() => {
                                    navigate(`${basename}/`);
                                })
                                .catch((error: any) => {
                                    notify(errorMessage(error, "ra.auth.sign_in_error"), { type: "error" });
                                });
                        }}
                    >
                        <img src={appleLoginImage} style={{ width: "240px", height: "auto", cursor: "pointer" }} />
                    </div>
                </div>
            </Card>
        </Root>
    );
};

const PREFIX = "WaveLogin";
export const LoginClasses = {
    card: `${PREFIX}-card`,
    icon: `${PREFIX}-icon`,
    methods: `${PREFIX}-methods`,
};

const Root = styled("div", {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: "6em",
    },
    [`& .${LoginClasses.icon}`]: {
        margin: "1em",
        display: "flex",
        justifyContent: "center",
    },
    [`& .${LoginClasses.methods}`]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
        gap: "5px",
    },
}));
