import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    SelectField,
    ReferenceField,
    ReferenceArrayField,
    ReferenceArrayInput,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";
import { ArtistReferenceInput } from "./artists";

export const DDEXArtistMappingList = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="name" />
            <TextField source="from_party_id" />
            <ReferenceField label="Artist" reference="artists" source="artist_id" />
        </Datagrid>
    </List>
);

export const DDEXArtistMappingShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>DDEXArtistMapping</h2>

            <TextField source="id" />
            <TextField source="name" />
            <TextField source="from_party_id" />
            <ReferenceField label="Artist" reference="artists" source="artist_id" />
            <ShortDateField source="created_at" label="Added on" />
        </SimpleShowLayout>
    </Show>
);

export const DDEXArtistMappingCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add DDEXArtistMapping</h2>

            <TextInput source="name" fullWidth />
            <TextInput source="from_party_id" fullWidth label="Party id" />
            <ArtistReferenceInput source="artist_id" />
        </SimpleForm>
    </Create>
);

export const DDEXArtistMappingEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit DDEXArtistMapping</h2>

            <TextInput source="name" fullWidth />
            <TextInput source="from_party_id" fullWidth label="Party id" />
            <ArtistReferenceInput source="artist_id" />
        </SimpleForm>
    </Edit>
);
