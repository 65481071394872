import React, { Fragment, ReactElement, useState } from "react";
import {
    BulkActionProps,
    ButtonProps,
    Confirm,
    Form,
    MutationMode,
    RaRecord,
    RecordContextProvider,
    SaveContextProvider,
    SimpleForm,
    TextInput,
    useListContext,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useUnselectAll,
    useUpdateMany,
} from "react-admin";
import PropTypes from "prop-types";
import { Button, alpha, styled } from "@mui/material";
import ActionUpdate from "@mui/icons-material/Update";
import ActionCheck from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { errorMessage } from "./utils";
import { httpClient } from "./dataProvider";
import { stringify } from "query-string";

export const BulkApi = (props: BulkApiFormProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const resource = useResourceContext(props);
    const unselectAll = useUnselectAll(resource);
    const [isOpen, setOpen] = useState(false);
    const { selectedIds } = useListContext(props);
    const [isLoading, setLoading] = useState(false);

    const { confirmLabel = "ra.message.bulk_update_title", api, ...rest } = props;

    const handleClick = (e) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        const query = {
            id: selectedIds,
        };

        httpClient(import.meta.env.VITE_SERVER_URL + `${api}?${stringify(query)}`, {
            method: "POST",
        })
            .then(() => {
                unselectAll();
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    return (
        <Fragment>
            <Confirm
                isOpen={isOpen}
                loading={false}
                title={`Apply ${selectedIds.length} ${resource}`}
                content={confirmLabel}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary">
                Apply
            </Button>
        </Fragment>
    );
};

export interface BulkApiFormProps<RecordType extends RaRecord = any, MutationOptionsError = unknown> extends BulkActionProps {
    confirmLabel?: string;
    api: string;
}

BulkApi.propTypes = {
    confirmLabel: PropTypes.string,
    api: PropTypes.string,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
};

const PREFIX = "BulkUpdateForm";
