import * as Sentry from "@sentry/react";

const SENTRY_ENABLED = import.meta.env.MODE !== "development";

export const initSentry = () => {
    if (!SENTRY_ENABLED) {
        return;
    }
    Sentry.init({
        dsn: "https://527e1f5e9d57cfbfa8a3ee39408657cd@o4506626036924416.ingest.sentry.io/4506831846572032",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            "wave-content-editor.netlify.app",
            "staging--wave-content-editor.netlify.app",
            "content.voodoo-music.com",
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: import.meta.env.MODE,
    });
};

export const setSentryUser = (user: { id: string; email?: string } | null) => {
    if (!SENTRY_ENABLED) {
        return;
    }
    Sentry.setUser(user);
};

export const captureError = (error: any) => {
    if (!SENTRY_ENABLED) {
        return;
    }
    Sentry.captureException(error);
};
