import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    useRecordContext,
    ReferenceInput,
    AutocompleteInput,
    fetchRelatedRecords,
    downloadCSV,
    ReferenceManyCount,
} from "react-admin";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { BulkActionButtons } from "./common";
import { MediaUploadField, ShortDateField } from "./customFields";
import { DEFAULT_IS_PUBLIC } from "./config";
import { dataProvider } from "./dataProvider";
import jsonExport from "jsonexport/dist";

const artistFilters = [<TextInput source="q" label="Search" alwaysOn />];

const exporter = async (labels: any) => {
    let genres = await fetchRelatedRecords(dataProvider)(labels, "genre_ids", "genres");

    const data = labels.map((label: any) => ({
        id: label.id,
        name: label.name,
        genres: label.genre_ids.map((genre_id: number) => genres[genre_id].name),
        is_public: label.is_public,
        image_url: label.image_url,
    }));

    jsonExport(
        data,
        {
            headers: ["id", "name", "genres", "is_public", "image_url"],
        },
        (err: any, csv: any) => {
            downloadCSV(csv, "labels");
        }
    );
};

export const LabelList = () => (
    <List
        filters={artistFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        exporter={exporter}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="name" />
            <ImageField source="image_url" title="Label image" label="Artwork" sortable={false} />
            <BooleanField source="is_public" label="Public" />
            <ShortDateField source="created_at" label="Added on" />
            <EditButton />
        </Datagrid>
    </List>
);

export const LabelShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Label Details</h2>

            <TextField source="name" />
            <ImageField source="image_url" title="Label image" label="Artwork" />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <BooleanField source="is_public" />
            <ReferenceManyCount label="Nb tracks" reference="tracks" target="label_id" link />
            <TextField source="id" />
            <ReferenceManyField
                label="Tracks preview (25 max)"
                reference="tracks"
                target="label_id"
                sort={{ field: "created_at", order: "DESC" }}
            >
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Album cover" label="Image" />
                    <ReferenceField source="artist_id" reference="artists" label="Artist" link="show" sortable={false} />
                    <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" sortable={false} />
                    <BooleanField source="is_public" label="Public" />
                    <ShortDateField source="created_at" label="Added on" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export const LabelEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Label</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Label Name"
            />
            <ReferenceArrayInput label="Genres" source="genre_ids" reference="genres" />
            <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
            <MediaUploadField mediaType="image" requiresValidation={false} />
        </SimpleForm>
    </Edit>
);

export const LabelCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Label</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Label Name"
            />
            <ReferenceArrayInput label="Genres" source="genre_ids" reference="genres" />
            <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
            <MediaUploadField mediaType="image" requiresValidation={false} />
        </SimpleForm>
    </Create>
);

const LabelOptionRenderer = () => {
    const record = useRecordContext();
    return (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src={record.thumbnail_url} style={{ width: "40px" }} />
            {record.name}
        </div>
    );
};
const labelOptionText = <LabelOptionRenderer />;
const labelInputText = (choice: any) => `${choice.name}`;

export const LabelReferenceInput = (props: { label?: string; source?: string }) => {
    const record = useRecordContext();
    const recordExists = !!record;

    const { label = "Label", source = "label_id" } = props;

    return (
        <ReferenceInput source={source} reference="labels" label={label}>
            <AutocompleteInput
                inputText={labelInputText}
                optionText={labelOptionText}
                defaultValue={recordExists ? undefined : null}
                fullWidth
            />
        </ReferenceInput>
    );
};
